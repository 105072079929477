import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import StyledBox from "components/elements/styledBox";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_establecimiento } from "utils/api/apiDirectorio";

const Establecimiento = () => {
  const theme = useTheme();
  const { establecimiento_slug } = useParams();
  const [establecimiento, setEstablecimiento] = useState(null);
  const [imagenSeleccionada, setImagenSeleccionada] = useState(0);

  useEffect(() => {
    const getEstablecimiento = async () => {
      var establecimiento = await get_establecimiento(establecimiento_slug);
      setEstablecimiento(establecimiento);
    };
    getEstablecimiento();
  }, [establecimiento_slug]);

  return (
    <Box>
      <FrontShort
        titulo="Guía Local de Facatativá "
        links={[
          { titulo: "Directorio", link: "/directorio" },
          {
            titulo: establecimiento?.nombre,
            link: "/directorio/detalles/" + establecimiento?.slug,
          },
        ]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "1rem" }}
                textAlign="center"
              >
                {establecimiento?.nombre}
              </Typography>
              <Box width="100%" px={{ xs: "0%", sm: "10%", md: "20%" }}></Box>
            </Box>
            <br />
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "5px",
                border: "1px solid lightgray",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <Box p="5%">
                    <Box>
                      <img
                        src={establecimiento?.fotos[imagenSeleccionada]}
                        alt={"Facatativá" + establecimiento?.nombre}
                        width="100%"
                      />
                    </Box>
                    <ImageList>
                      {establecimiento?.fotos?.map((foto, index) => (
                        <Button
                          onClick={() => setImagenSeleccionada(index)}
                          sx={{ padding: "0px" }}
                          key={index}
                        >
                          <ImageListItem key={index}>
                            <img
                              src={foto}
                              alt={"Facatativá" + establecimiento?.nombre}
                              loading="lazy"
                            />
                          </ImageListItem>
                        </Button>
                      ))}
                    </ImageList>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Box p="5%">
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Dirección
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography
                            variant="body2"
                            color={theme.palette.neutral.main}
                          >
                            {establecimiento?.direccion
                              ? establecimiento?.direccion
                              : "No disponible"}
                          </Typography>
                          <IconButton
                            component="a"
                            size="small"
                            target="_blank"
                            rel="noopener"
                            href={
                              "https://www.google.com/maps/dir//" +
                              establecimiento?.latitud +
                              "," +
                              establecimiento?.longitud
                            }
                          >
                            <LaunchIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Sitio Web
                        </Typography>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          {establecimiento?.sitioWeb
                            ? establecimiento?.sitioWeb
                            : "No disponible"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Teléfono
                        </Typography>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          {establecimiento?.telefono1
                            ? establecimiento?.telefono1
                            : "No disponible"}
                        </Typography>
                        {establecimiento?.telefono2 && (
                          <Typography
                            variant="body2"
                            color={theme.palette.neutral.main}
                          >
                            {establecimiento.telefono2}
                          </Typography>
                        )}
                        {establecimiento?.telefono3 && (
                          <Typography
                            variant="body2"
                            color={theme.palette.neutral.main}
                          >
                            {establecimiento.telefono2}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Horario
                        </Typography>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          {establecimiento?.horario
                            ? establecimiento?.horario
                            : "No disponible"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Descripción
                        </Typography>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                          textAlign="justify"
                          whiteSpace="pre-wrap"
                        >
                          {establecimiento?.descripcion
                            ? establecimiento?.descripcion
                            : "No disponible"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Establecimiento;
