import InstallMobileOutlinedIcon from "@mui/icons-material/InstallMobileOutlined";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import logo from "assets/logo.png";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.neutral.main,
  textDecoration: "none",
  marginBottom: "0.5rem",
  "&:hover": {
    color: theme.palette.secondary.main,
  },
}));

const Footer = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box mt={7} mb={{ xs: 5, md: 10 }}>
        <Container>
          <Container>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography fontWeight="bold" mb="1rem">
                    Enlaces de Interés
                  </Typography>
                  <StyledLink href="/">Explorar</StyledLink>
                  <StyledLink href="/inmobiliaria">Inmobiliaria</StyledLink>
                  <StyledLink href="/elecciones">Elecciones</StyledLink>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography fontWeight="bold" mb="1rem">
                    Conecta con Nosotros
                  </Typography>
                  <StyledLink
                    href="mailto:facatativapp@gmail.com"
                    target="_blank"
                    rel="noopener"
                  >
                    Escríbenos
                  </StyledLink>
                  <StyledLink
                    href="https://www.facebook.com/facatativapp"
                    target="_blank"
                    rel="noopener"
                  >
                    Facebook
                  </StyledLink>
                  <StyledLink
                    href="https://www.instagram.com/facatativapp/"
                    target="_blank"
                    rel="noopener"
                  >
                    Instagram
                  </StyledLink>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography fontWeight="bold" mb="1rem">
                    Ayuda
                  </Typography>
                  <StyledLink
                    href="mailto:facatativapp@gmail.com"
                    target="_blank"
                    rel="noopener"
                  >
                    Preguntas Frecuentes
                  </StyledLink>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography fontWeight="bold" mb="1rem">
                    FacatativApp
                  </Typography>
                  <Typography
                    mb="2rem"
                    sx={{ color: theme.palette.neutral.main }}
                  >
                    Descarga FacatativApp en tu teléfono y descubre tu ciudad.
                  </Typography>
                  <Button
                    component="a"
                    color="secondary"
                    sx={{ textTransform: "none", color: "white" }}
                    startIcon={<InstallMobileOutlinedIcon />}
                    variant="contained"
                    size="large"
                    href="https://play.google.com/store/apps/details?id=com.facatativa"
                    target="_blank"
                    rel="noopener"
                  >
                    Descargar desde Play Store
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
      <Divider />
      <Box my={1}>
        <Grid container alignItems="stretch" rowSpacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <img src={logo} alt="FacatativApp" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography>
                © {new Date().getFullYear()} FacatativApp. Hecho con ❤️
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Button
                component="a"
                variant="outlined"
                sx={{ textTransform: "none" }}
                href="/publicar"
              >
                Publicar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
