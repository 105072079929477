import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import imagenEstablecimiento from "assets/shop.png";
import imagenInmueble from "assets/sign.png";
import StyledBox from "components/elements/styledBox/styledBox";
import Footer from "components/modules/footer/footer";
import FrontShort from "components/modules/frontShort/frontShort";

const Publicar = () => {
  const theme = useTheme();
  return (
    <Box>
      <FrontShort
        titulo="Publicar"
        links={[{ titulo: "Publicar", link: "/publicar" }]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" fontWeight="bold" sx={{ mb: "1rem" }}>
                Publicar en FacatativApp
              </Typography>
              <Typography color={theme.palette.neutral.main}>
                ¿Qué información quieres publicar?
              </Typography>
            </Box>
            <br />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    border: "1px solid lightgray",
                  }}
                  width="100%"
                >
                  <Button
                    component="a"
                    sx={{ width: "100%" }}
                    target="_blank"
                    rel="noopener"
                    href="https://docs.google.com/forms/d/1Zq5s4diQosZW5mhYxMfhYxcreg4sre_MWSLCeXCDW2k"
                  >
                    <Stack
                      alignItems="center"
                      sx={{
                        textTransform: "none",
                        color: theme.palette.secondary.main,
                      }}
                    >
                      <img
                        src={imagenEstablecimiento}
                        width="256px"
                        alt="FacatativApp - Publicar establecimiento"
                      />
                      <Typography fontWeight="bold">Establecimiento</Typography>
                    </Stack>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    border: "1px solid lightgray",
                  }}
                  width="100%"
                >
                  <Button
                    component="a"
                    sx={{ width: "100%" }}
                    target="_blank"
                    rel="noopener"
                    href="https://docs.google.com/forms/d/1PmDbioXIbOy_Rt88LfXWAvu-8LYTXSp1B_hKOQos1u4"
                  >
                    <Stack
                      alignItems="center"
                      sx={{
                        textTransform: "none",
                        color: theme.palette.secondary.main,
                      }}
                    >
                      <img
                        src={imagenInmueble}
                        width="256px"
                        alt="FacatativApp - Publicar inmueble"
                      />
                      <Typography fontWeight="bold">Inmueble</Typography>
                    </Stack>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Publicar;
