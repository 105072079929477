import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import StyledBox from "components/elements/styledBox";
import StyledElement from "components/elements/styledElement/styledElement";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_inmuebles } from "utils/api/apiInmuebles";

const Inmuebles = () => {
  const { tipo } = useParams();
  const [busqueda, setBusqueda] = useState("");
  const [inmuebles, setInmuebles] = useState([]);

  useEffect(() => {
    const getInmuebles = async () => {
      var inmuebles = await get_inmuebles(tipo);
      setInmuebles(inmuebles);
    };
    getInmuebles();
  }, [tipo]);

  return (
    <Box>
      <FrontShort
        titulo="Guía Local de Facatativá "
        links={[
          { titulo: "Inmobiliaria", link: "/inmobiliaria" },
          {
            titulo: inmuebles?.tipo?.nombre,
            link: "/inmobiliaria/categorias/" + inmuebles?.tipo?.slug,
          },
        ]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "1rem" }}
                textAlign="center"
              >
                Descubre {inmuebles?.tipo?.nombre} en Facatativá
              </Typography>
              <Box width="100%" px={{ xs: "0%", sm: "10%", md: "20%" }}>
                <OutlinedInput
                  fullWidth
                  placeholder="Buscar"
                  sx={{ backgroundColor: "white" }}
                  value={busqueda}
                  onChange={(event) => {
                    setBusqueda(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setBusqueda("")}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
            </Box>
            <br />
            <Grid container spacing={4}>
              {inmuebles?.inmuebles
                ?.filter((inmueble) =>
                  inmueble.barrio.toLowerCase().includes(busqueda.toLowerCase())
                )
                .map((inmueble, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <StyledElement
                      titulo={inmueble.barrio}
                      foto={inmueble.foto}
                      href={"/inmobiliaria/inmueble/" + inmueble.slug}
                    />
                  </Grid>
                ))}
            </Grid>
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Inmuebles;
