import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  Grid,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import portada from "assets/facatativa.jpg";
import Header from "components/modules/header/header";

const StyledButton = styled(Button)(({ theme }) => ({
  height: "100%",
  padding: "3% 2%",
  border: "1px solid #7F8C8D",
  borderRadius: "0px",
  textTransform: "none",
  color: "#BDC3C7",
  "&:hover": {
    borderColor: "white",
    color: "white",
  },
}));

const frontExtended = () => {
  return (
    <Box
      height="100vh"
      sx={{
        background: `linear-gradient(45deg, #13532eDD, #5b130bDD), url(${portada})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Header />
      <Container>
        <Box
          width="100%"
          height="90vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Typography
            sx={{ color: "white" }}
            variant="h4"
            fontWeight="bold"
            mb={"0.5rem"}
            textAlign="center"
          >
            Los mejores lugares de Facatativá
          </Typography>
          <Typography sx={{ color: "white" }} mb="2rem" textAlign="center">
            Descubre restaurantes, sitios de interés, tecnología y mucho más...
          </Typography>

          <Box
            backgroundColor="white"
            width="80%"
            mx="10%"
            p="3%"
            borderRadius="5px"
            mb="2rem"
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <TextField placeholder="¿Qué estás buscando?" fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: "100%", textTransform: "none", color: "white" }}
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            sx={{ gap: 1 }}
          >
            <StyledButton
              component="a"
              href="directorio/categorias/restaurantes"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <RestaurantOutlinedIcon sx={{ color: "#3498DB" }} />
                <Typography>Restaurantes</Typography>
              </Box>
            </StyledButton>
            <StyledButton
              component="a"
              href="directorio/categorias/alojamiento"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <BedOutlinedIcon sx={{ color: "#2ECC71" }} />
                <Typography>Hoteles</Typography>
              </Box>
            </StyledButton>
            <StyledButton
              component="a"
              href="directorio/categorias/servicios-de-emergencia"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <MedicalServicesOutlinedIcon sx={{ color: "#E74C3C" }} />
                <Typography>Emergencia</Typography>
              </Box>
            </StyledButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default frontExtended;
