import { Box, Container, Grid, Typography } from "@mui/material";
import StyledBox from "components/elements/styledBox";
import StyledCard from "components/elements/styledCard";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import { get_categorias_directorio } from "utils/api/apiDirectorio";

const Categorias = () => {
  const [categoriasDirectorio, setCategoriasDirectorio] = useState([]);

  useEffect(() => {
    getCategoriasDirectorio();
  }, []);

  const getCategoriasDirectorio = async () => {
    setCategoriasDirectorio(await get_categorias_directorio("all"));
  };

  return (
    <Box>
      <FrontShort
        titulo="Guía Local de Facatativá "
        links={[{ titulo: "Directorio", link: "/directorio" }]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "1rem" }}
                textAlign="center"
              >
                ¿Qué quieres hacer hoy?
              </Typography>
              <Typography textAlign="center">
                Descubre los mejores restaurantes, tiendas, cafés y más lugares
                increíbles en tu ciudad.
              </Typography>
            </Box>
            <br />
            <Grid container spacing={4}>
              {categoriasDirectorio.map((categoria, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <StyledCard
                    categoria={categoria}
                    link={"/directorio/categorias/" + categoria.slug}
                  />
                </Grid>
              ))}
            </Grid>
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Categorias;
