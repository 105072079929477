import { useTheme } from "@emotion/react";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";

const StyledElement = ({ titulo, subtitulo, foto, onClick, href }) => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      p="5%"
      sx={{
        backgroundColor: "white",
        borderRadius: "3px",
        border: "1px solid lightgray",
      }}
    >
      <Stack alignItems="center">
        <Avatar src={foto} sx={{ width: 128, height: 128, my: 1 }} />
        <Box mb={3}>
          <Typography color="secondary" fontWeight="bold" textAlign="center">
            {titulo}
          </Typography>
          {subtitulo && (
            <Typography
              color={theme.palette.neutral.main}
              textAlign="center"
              variant="body2"
            >
              {subtitulo}
            </Typography>
          )}
        </Box>
        {onClick && (
          <Button
            variant="contained"
            sx={{ color: "white", textTransform: "none" }}
            onClick={onClick}
          >
            Ver detalles
          </Button>
        )}
        {href && (
          <Button
            component="a"
            variant="contained"
            sx={{ color: "white", textTransform: "none" }}
            href={href}
          >
            Ver detalles
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default StyledElement;
