import { url_api } from "utils/constants";

const get_candidaturas = async (tipo) => {
  const url = url_api + "elecciones/get_candidaturas";
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const get_candidatos = async (candidatura) => {
  const url = url_api + "elecciones/get_candidatos/" + candidatura;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const get_candidato = async (candidato) => {
  const url = url_api + "elecciones/get_candidato/" + candidato;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

export { get_candidato, get_candidatos, get_candidaturas };
