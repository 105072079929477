const navegacion = [
  {
    nombre: "Directorio",
    url: "/directorio",
  },
  {
    nombre: "Inmobiliaria",
    url: "/inmobiliaria",
  },
  {
    nombre: "Elecciones",
    url: "/elecciones",
  },
];

export default navegacion;
