import { Box, Container, Grid, Typography } from "@mui/material";
import StyledBox from "components/elements/styledBox";
import StyledCard from "components/elements/styledCard";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import { get_tipos_inmuebles } from "utils/api/apiInmuebles";

const Categorias = () => {
  const [tiposInmuebles, setTiposInmuebles] = useState([]);

  useEffect(() => {
    getTiposInmuebles();
  }, []);

  const getTiposInmuebles = async () => {
    setTiposInmuebles(await get_tipos_inmuebles("all"));
  };

  return (
    <Box>
      <FrontShort
        titulo="Inmuebles en Facatativá"
        links={[{ titulo: "Inmobiliaria", link: "/inmobiliaria" }]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "1rem" }}
                textAlign="center"
              >
                Inmobiliaria
              </Typography>
              <Typography textAlign="center">
                Casas y apartamentos en arriendo y en venta.
              </Typography>
            </Box>
            <br />
            <Grid container spacing={4}>
              {tiposInmuebles.map((categoria, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <StyledCard
                    categoria={categoria}
                    link={"inmobiliaria/categorias/" + categoria.slug}
                  />
                </Grid>
              ))}
            </Grid>
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Categorias;
