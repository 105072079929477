import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import logo from "assets/logo.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import navegacion from "utils/navigation";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const CustomDrawer = () => {
    return (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Box sx={{ my: 2 }}>
          <Button component="a" href="/">
            <img src={logo} alt="FacatativApp" />
          </Button>
        </Box>
        <Divider />
        <List>
          {navegacion.map((opcion, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                onClick={() => navigate(opcion.url)}
              >
                <ListItemText primary={opcion.nombre} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box display="flex" height="10vh">
      <AppBar
        component="nav"
        sx={{ backgroundColor: "transparent" }}
        elevation={0}
        position="static"
      >
        <Toolbar>
          <Box display={{ xs: "block", md: "none" }} flexGrow={1}>
            <IconButton>
              <MenuIcon onClick={handleDrawerToggle} sx={{ color: "white" }} />
            </IconButton>
          </Box>
          <Box
            display={{ xs: "none", md: "flex" }}
            alignContent="center"
            pr={3}
            mr={3}
            sx={{ borderRight: "1px solid #777" }}
          >
            <Button component="a" href="/">
              <img src={logo} alt="FacatativApp" />
            </Button>
          </Box>
          <Box display={{ xs: "none", md: "block" }} flexGrow={1}>
            {navegacion.map((opcion, index) => (
              <Button
                component="a"
                key={index}
                sx={{ color: "#fff", textTransform: "none", mr: 1 }}
                href={opcion.url}
              >
                {opcion.nombre}
              </Button>
            ))}
          </Box>
          <Box>
            <Button
              component="a"
              variant="contained"
              color="primary"
              sx={{ color: "#fff", textTransform: "none" }}
              startIcon={<EditOutlinedIcon sx={{ color: "white" }} />}
              href="/publicar"
            >
              Publicar
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
        >
          <CustomDrawer />
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
