import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import StyledBox from "components/elements/styledBox";
import StyledElement from "components/elements/styledElement";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import {
  get_candidato,
  get_candidatos,
  get_candidaturas,
} from "utils/api/apiElecciones";

const DetallesCandidato = ({
  candidatoSeleccionado,
  setCandidatoSeleccionado,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={candidatoSeleccionado}
      onClose={() => setCandidatoSeleccionado(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Box>
          <Typography color="secondary" fontWeight="bold">
            {candidatoSeleccionado.nombre}
          </Typography>
          <Typography color={theme.palette.neutral.main} variant="body2">
            {candidatoSeleccionado.partido}
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setCandidatoSeleccionado(null)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center">
              <Box sx={{ p: 1 }}>
                <img
                  src={candidatoSeleccionado.foto}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                  alt={"FacatativApp - " + candidatoSeleccionado.nombre}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  variant="subtitle2"
                >
                  Email
                </Typography>
                <Typography variant="body2" color={theme.palette.neutral.main}>
                  {candidatoSeleccionado.email
                    ? candidatoSeleccionado.email
                    : "No disponible"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  variant="subtitle2"
                >
                  Sitio Web
                </Typography>
                <Typography variant="body2" color={theme.palette.neutral.main}>
                  {candidatoSeleccionado.sitioWeb
                    ? candidatoSeleccionado.sitioWeb
                    : "No disponible"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  variant="subtitle2"
                >
                  Teléfono
                </Typography>
                <Typography variant="body2" color={theme.palette.neutral.main}>
                  {candidatoSeleccionado.telefono
                    ? candidatoSeleccionado.telefono
                    : "No disponible"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  variant="subtitle2"
                >
                  Perfil
                </Typography>
                <Typography variant="body2" color={theme.palette.neutral.main}>
                  {candidatoSeleccionado.perfil
                    ? candidatoSeleccionado.perfil
                    : "No disponible"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  variant="subtitle2"
                >
                  Experiencia
                </Typography>
                <Typography variant="body2" color={theme.palette.neutral.main}>
                  {candidatoSeleccionado.experiencia
                    ? candidatoSeleccionado.experiencia
                    : "No disponible"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="secondary"
                  fontWeight="bold"
                  variant="subtitle2"
                >
                  Propuestas
                </Typography>
                <Typography variant="body2" color={theme.palette.neutral.main}>
                  {candidatoSeleccionado.propuestas
                    ? candidatoSeleccionado.propuestas
                    : "No disponible"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const Candidaturas = () => {
  const [candidaturas, setCandidaturas] = useState([]);
  const [candidatura, setCandidatura] = useState(0);
  const [candidatos, setCandidatos] = useState([]);
  const [candidatoSeleccionado, setCandidatoSeleccionado] = useState(null);

  useEffect(() => {
    getCandidaturas();
  }, []);

  const getCandidaturas = async () => {
    var candidaturas = await get_candidaturas();
    setCandidaturas(candidaturas);
  };

  const seleccionarCandidatura = async (candidatura) => {
    setCandidatura(candidatura);
    var candidatos = candidatura ? await get_candidatos(candidatura) : [];
    setCandidatos(candidatos);
  };

  const seleccionarCandidato = async (idCandidato) => {
    var candidato = await get_candidato(idCandidato);
    setCandidatoSeleccionado(candidato);
  };

  return (
    <Box>
      <FrontShort
        titulo="Elecciones en Facatativá "
        links={[{ titulo: "Elecciones", link: "/elecciones" }]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                textAlign="center"
                sx={{ mb: "1rem" }}
              >
                Seleccionar candidatura
              </Typography>
              <Box width="100%" px={{ xs: "0%", sm: "10%", md: "20%" }}>
                <Select
                  value={candidatura}
                  onChange={(event) =>
                    seleccionarCandidatura(event.target.value)
                  }
                  fullWidth
                  sx={{ backgroundColor: "white" }}
                >
                  <MenuItem value={0}>Selecciona candidatura</MenuItem>
                  {candidaturas.map((candidatura, index) => (
                    <MenuItem value={candidatura.id} key={index}>
                      {candidatura.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <br />
            {candidatos.length > 0 ? (
              <Grid container spacing={4}>
                {candidatos.map((candidato, key) => (
                  <Grid item xs={12} sm={6} md={4} key={key}>
                    <StyledElement
                      titulo={candidato.nombre}
                      subtitulo={candidato.partido}
                      foto={candidato.foto}
                      onClick={() => seleccionarCandidato(candidato.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
      {candidatoSeleccionado && (
        <DetallesCandidato
          candidatoSeleccionado={candidatoSeleccionado}
          setCandidatoSeleccionado={setCandidatoSeleccionado}
        />
      )}
    </Box>
  );
};

export default Candidaturas;
