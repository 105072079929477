import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import HowToVoteOutlinedIcon from "@mui/icons-material/HowToVoteOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import app from "assets/app.png";

const BannerDescarga = () => {
  const theme = useTheme();
  return (
    <Box py="3rem" backgroundColor={"#fafafa"}>
      <Box>
        <Container>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h4"
              fontWeight="bold"
              color="secondary"
              sx={{ mb: "1rem" }}
              textAlign="center"
            >
              Lleva FacatativApp contigo a todas partes
            </Typography>
            <Typography color={theme.palette.neutral.main} textAlign="center">
              Descubre la App y redescubre tu ciudad
            </Typography>
          </Box>
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box px="5%">
                <img src={app} width="100%" alt="FacatativApp" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box px="5%">
                <Box ml="10%">
                  <Stack direction="row" spacing={2} mb="2rem">
                    <Avatar sx={{ bgcolor: "#f4ebff", width: 64, height: 64 }}>
                      <NearMeOutlinedIcon
                        sx={{ width: 32, height: 32, color: "#903af9" }}
                      />
                    </Avatar>
                    <Stack>
                      <Typography
                        fontWeight="bold"
                        mb="0.5rem"
                        color="secondary"
                      >
                        Directorio
                      </Typography>
                      <Typography sx={{ color: theme.palette.neutral.main }}>
                        Redescubre tu ciudad y explora los mejores lugares de
                        Facatativá
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={2} mb="2rem">
                    <Avatar sx={{ bgcolor: "#eafaf0", width: 64, height: 64 }}>
                      <HowToVoteOutlinedIcon
                        sx={{ width: 32, height: 32, color: "#32cc6f" }}
                      />
                    </Avatar>
                    <Stack>
                      <Typography
                        fontWeight="bold"
                        mb="0.5rem"
                        color="secondary"
                      >
                        Elecciones
                      </Typography>
                      <Typography sx={{ color: theme.palette.neutral.main }}>
                        Los candidatos, sus perfiles, sus propuestas... Todo
                        está aquí!
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={2} mb="2rem">
                    <Avatar sx={{ bgcolor: "#feedf3", width: 64, height: 64 }}>
                      <HouseOutlinedIcon
                        sx={{ width: 32, height: 32, color: "#f5548e" }}
                      />
                    </Avatar>
                    <Stack>
                      <Typography
                        fontWeight="bold"
                        mb="0.5rem"
                        color="secondary"
                      >
                        Inmobiliaria
                      </Typography>
                      <Typography sx={{ color: theme.palette.neutral.main }}>
                        El hogar de tus sueños puede estar a unos pocos clicks
                        de distancia!
                      </Typography>
                    </Stack>
                  </Stack>
                  <Button
                    component="a"
                    variant="contained"
                    sx={{ textTransform: "none", color: "white" }}
                    size="large"
                    href="https://play.google.com/store/apps/details?id=com.facatativa"
                    target="_blank"
                    rel="noopener"
                  >
                    Descargar FacatativApp
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default BannerDescarga;
