import { url_api } from "utils/constants";

const get_tipos_inmuebles = async (tipo) => {
  const url = url_api + "inmobiliaria/get_tipos_inmueble/" + tipo;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const get_inmuebles = async (tipo) => {
  const url = url_api + "inmobiliaria/get_inmuebles/" + tipo;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const get_inmueble = async (inmueble) => {
  const url = url_api + "inmobiliaria/get_inmueble/" + inmueble;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

export { get_tipos_inmuebles, get_inmuebles, get_inmueble };
