import { Home, Publicar } from "pages/general";

const routes = [
  {
    path: "",
    page: <Home />,
  },
  {
    path: "/publicar",
    page: <Publicar />,
  },
];

export default routes;
