import {
  Categorias,
  Subcategorias,
  Establecimientos,
  Establecimiento,
} from "pages/directorio";

const routes = [
  {
    path: "",
    page: <Categorias />,
  },
  {
    path: "categorias/:categoria",
    page: <Subcategorias />,
  },
  {
    path: "establecimientos/:subcategoria",
    page: <Establecimientos />,
  },
  {
    path: "detalles/:establecimiento_slug",
    page: <Establecimiento />,
  },
];

export default routes;
