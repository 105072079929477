import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import StyledBox from "components/elements/styledBox";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_inmueble } from "utils/api/apiInmuebles";

const formatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});

const Inmueble = () => {
  const theme = useTheme();
  const { inmueble_slug } = useParams();
  const [imagenSeleccionada, setImagenSeleccionada] = useState(0);
  const [inmueble, setInmueble] = useState(null);

  useEffect(() => {
    const getInmueble = async () => {
      var inmueble = await get_inmueble(inmueble_slug);
      setInmueble(inmueble);
    };
    getInmueble();
  }, [inmueble_slug]);

  return (
    <Box>
      <FrontShort
        titulo="Inmuebles en Facatativá"
        links={[
          { titulo: "Inmobiliaria", link: "/inmobiliaria" },
          {
            titulo: inmueble?.tipo?.nombre,
            link: "/inmobiliaria/categorias/" + inmueble?.tipo?.slug,
          },
        ]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "1rem" }}
                textAlign="center"
              >
                {inmueble?.tipo?.nombre} en Facatativá
              </Typography>
            </Box>
            <br />
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "5px",
                border: "1px solid lightgray",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <Box p="5%">
                    <Box>
                      <img
                        src={inmueble?.fotos[imagenSeleccionada]}
                        alt={"Facatativá" + inmueble?.nombre}
                        width="100%"
                      />
                    </Box>
                    <ImageList>
                      {inmueble?.fotos?.map((foto, index) => (
                        <Button
                          onClick={() => setImagenSeleccionada(index)}
                          sx={{ padding: "0px" }}
                        >
                          <ImageListItem key={index}>
                            <img
                              src={foto}
                              alt={"Facatativá" + inmueble?.nombre}
                              loading="lazy"
                            />
                          </ImageListItem>
                        </Button>
                      ))}
                    </ImageList>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Box p="5%">
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Dirección
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography
                            variant="body2"
                            color={theme.palette.neutral.main}
                          >
                            {inmueble?.direccion
                              ? inmueble?.direccion + " - " + inmueble?.barrio
                              : "No disponible"}
                          </Typography>
                          <IconButton
                            component="a"
                            size="small"
                            target="_blank"
                            rel="noopener"
                            href={
                              "https://www.google.com/maps/dir//" +
                              inmueble?.latitud +
                              "," +
                              inmueble?.longitud
                            }
                          >
                            <LaunchIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Contacto
                        </Typography>
                        {inmueble?.telefono && (
                          <Typography
                            variant="body2"
                            color={theme.palette.neutral.main}
                          >
                            {inmueble.telefono}
                          </Typography>
                        )}
                        {inmueble?.email && (
                          <Typography
                            variant="body2"
                            color={theme.palette.neutral.main}
                          >
                            {inmueble.email}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Área
                        </Typography>

                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          {inmueble?.area
                            ? inmueble?.area + " m2"
                            : "No disponible"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Capacidad (Personas)
                        </Typography>

                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          {inmueble?.capacidadPersonas
                            ? inmueble?.capacidadPersonas
                            : "No disponible"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Número de Habitaciones
                        </Typography>

                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          {inmueble?.numeroHabitaciones
                            ? inmueble?.numeroHabitaciones
                            : "No disponible"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Número de Baños
                        </Typography>

                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          {inmueble?.numeroBanios
                            ? inmueble?.numeroBanios
                            : "No disponible"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Descripción
                        </Typography>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                          textAlign="justify"
                        >
                          {inmueble?.descripcion
                            ? inmueble?.descripcion
                            : "No disponible"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          color="secondary"
                          fontWeight="bold"
                          variant="subtitle2"
                        >
                          Precios
                        </Typography>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          ▪️ Venta:{" "}
                          {inmueble?.precioVenta
                            ? inmueble.precioVenta
                            : "No disponible"}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={theme.palette.neutral.main}
                        >
                          ▪️ Arriendo:{" "}
                          {inmueble?.precioArriendo
                            ? formatter.format(inmueble?.precioArriendo)
                            : "No disponible"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Inmueble;
