import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2ecc71",
    },
    secondary: {
      main: "#272b41",
    },
    neutral: {
      main: "#7a82a6",
    },
  },
});

export default theme;
