import { Box } from "@mui/material";
import background from "assets/background.png";

const StyledBox = ({ children }) => {
  return (
    <Box
      py="3rem"
      sx={{
        background: `linear-gradient(to right, #fff5f880, #fff5f880), url(${background})`,
      }}
    >
      {children}
    </Box>
  );
};

export default StyledBox;
