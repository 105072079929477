import {
  Box,
  Container,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import StyledBox from "components/elements/styledBox";
import StyledCard from "components/elements/styledCard";
import Footer from "components/modules/footer/footer";
import FrontExtended from "components/modules/frontExtended/frontExtended";
import { useEffect, useState } from "react";
import { get_categorias_directorio } from "utils/api/apiDirectorio";
import { get_tipos_inmuebles } from "utils/api/apiInmuebles";
import BannerDescarga from "./components/bannerDescarga";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.neutral.main,
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.secondary.main,
  },
}));

const Home = () => {
  const theme = useTheme();
  const [tiposInmueble, setTiposInmuebles] = useState([]);
  const [categoriasDirectorio, setCategoriasDirectorio] = useState([]);

  useEffect(() => {
    getCategoriasDirectorio();
    getTiposInmuebles();
  }, []);

  const getCategoriasDirectorio = async () => {
    setCategoriasDirectorio(await get_categorias_directorio("featured"));
  };

  const getTiposInmuebles = async () => {
    setTiposInmuebles(await get_tipos_inmuebles("featured"));
  };

  return (
    <Box>
      <FrontExtended />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb="2rem"
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "0.5rem" }}
                color="secondary"
                textAlign="center"
              >
                ¿Qué quieres hacer hoy?
              </Typography>
              <Typography color={theme.palette.neutral.main} textAlign="center">
                Descubre los mejores restaurantes, tiendas, cafés y más lugares
                increíbles en tu ciudad.
              </Typography>
            </Box>
            <Box mb="3rem">
              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography color="secondary" fontWeight="bold" variant="h5">
                    Directorio
                  </Typography>
                  <StyledLink href="/directorio">Ver más</StyledLink>
                </Stack>
              </Box>
              <Box>
                <Grid container columnSpacing={4}>
                  {categoriasDirectorio.map((categoria, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                      <StyledCard
                        categoria={categoria}
                        link={"/directorio/categorias/" + categoria.slug}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <Box mb="3rem">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography color="secondary" fontWeight="bold" variant="h5">
                  Inmobiliaria
                </Typography>
                <StyledLink href="/inmobiliaria">Ver más</StyledLink>
              </Stack>
              <Grid container columnSpacing={4}>
                {tiposInmueble.map((tipo, index) => (
                  <Grid key={index} item xs={12} sm={6} md={3}>
                    <StyledCard
                      categoria={tipo}
                      link={"/inmobiliaria/" + tipo.slug}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </StyledBox>
      <BannerDescarga />
      <Footer />
    </Box>
  );
};

export default Home;
