import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Container,
  Link,
  Typography,
  styled,
} from "@mui/material";
import portada from "assets/facatativa.jpg";
import Header from "components/modules/header/header";

const StyledLink = styled(Link)(({ theme }) => ({
  alignItems: "center",
  color: "white",
  display: "flex",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const frontShort = ({ titulo, links }) => {
  return (
    <Box
      height="40vh"
      sx={{
        background: `linear-gradient(45deg, #13532eDD, #5b130bDD), url(${portada})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Header />
      <Container>
        <Box
          width="100%"
          height="30vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Typography
            sx={{ color: "white" }}
            variant="h4"
            fontWeight="bold"
            mb={"0.5rem"}
            textAlign="center"
          >
            {titulo}
          </Typography>
          <Breadcrumbs
            sx={{
              "& ol": {
                justifyContent: "center",
                margin: "auto",
              },
            }}
            separator={
              <NavigateNextIcon fontSize="small" sx={{ color: "lightgray" }} />
            }
          >
            <StyledLink href="/">Inicio</StyledLink>
            {links.map((link, index) => (
              <StyledLink key={index} href={link.link}>
                {link.titulo}
              </StyledLink>
            ))}
          </Breadcrumbs>
        </Box>
      </Container>
    </Box>
  );
};

export default frontShort;
