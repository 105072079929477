import directorioRoutes from "pages/directorio/routes";
import eleccionesRoutes from "pages/elecciones/routes";
import generalRoutes from "pages/general/routes";
import inmobiliariaRoutes from "pages/inmobiliaria/routes";
import { Route, Routes } from "react-router-dom";

const routes = () => {
  return (
    <Routes>
      {generalRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.page} />
      ))}
      {directorioRoutes.map((route, index) => (
        <Route
          key={index}
          path={"directorio/" + route.path}
          element={route.page}
        />
      ))}
      {eleccionesRoutes.map((route, index) => (
        <Route
          key={index}
          path={"elecciones/" + route.path}
          element={route.page}
        />
      ))}
      {inmobiliariaRoutes.map((route, index) => (
        <Route
          key={index}
          path={"inmobiliaria/" + route.path}
          element={route.page}
        />
      ))}
    </Routes>
  );
};

export default routes;
