import { Box, Button, Typography } from "@mui/material";

const StyledCard = ({ categoria, link }) => {
  return (
    <Button
      component="a"
      sx={{
        width: "100%",
        height: "200px",
        padding: "0px",
        borderRadius: "5px",

        background: `linear-gradient(to right, #00000077, #00000077), url(${categoria.foto})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        "&:hover": {
          background: `linear-gradient(135deg, #13532eEE, #5b130bEE), url(${categoria.foto})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
      }}
      href={link}
    >
      <Box display="flex" width="100%" height="100%" borderRadius="5px" sx={{}}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <img
            src={categoria.icono}
            width="32px"
            height="32px"
            alt={"facatativApp " + categoria.nombre}
          />
          <Typography color="#fff" mt={"0.5rem"} sx={{ textTransform: "none" }}>
            {categoria.nombre}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export default StyledCard;
