import { Box, Container, Grid, Typography } from "@mui/material";
import StyledBox from "components/elements/styledBox";
import StyledCard from "components/elements/styledCard";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_subcategorias_directorio } from "utils/api/apiDirectorio";

const Subcategorias = () => {
  const { categoria } = useParams();
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    const getCategorias = async () => {
      var categorias = await get_subcategorias_directorio(categoria);
      setCategorias(categorias);
    };
    getCategorias();
  }, [categoria]);

  return (
    <Box>
      <FrontShort
        titulo="Guía Local de Facatativá "
        links={[
          { titulo: "Directorio", link: "/directorio" },
          {
            titulo: categorias?.categoria?.nombre,
            link: "/" + categorias?.categoria,
          },
        ]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "1rem" }}
                textAlign="center"
              >
                Descubre {categorias?.categoria?.nombre} en Facatativá
              </Typography>
            </Box>
            <br />
            <Grid container spacing={4}>
              {categorias?.subcategorias?.map((subcategoria, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <StyledCard
                    categoria={subcategoria}
                    link={"/directorio/establecimientos/" + subcategoria.slug}
                  />
                </Grid>
              ))}
            </Grid>
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Subcategorias;
