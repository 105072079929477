import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import StyledBox from "components/elements/styledBox";
import StyledElement from "components/elements/styledElement/styledElement";
import Footer from "components/modules/footer";
import FrontShort from "components/modules/frontShort";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_establecimientos } from "utils/api/apiDirectorio";

const Establecimientos = () => {
  const { subcategoria } = useParams();
  const [establecimientos, setEstablecimientos] = useState([]);
  const [busqueda, setBusqueda] = useState("");

  useEffect(() => {
    const getEstablecimientos = async () => {
      var establecimientos = await get_establecimientos(subcategoria);
      setEstablecimientos(establecimientos);
    };
    getEstablecimientos();
  }, [subcategoria]);

  return (
    <Box>
      <FrontShort
        titulo="Guía Local de Facatativá "
        links={[
          { titulo: "Directorio", link: "/directorio" },
          {
            titulo: establecimientos?.subcategoria?.categoria.nombre,
            link:
              "/directorio/categorias/" +
              establecimientos?.subcategoria?.categoria.slug,
          },
          {
            titulo: establecimientos?.subcategoria?.nombre,
            link:
              "/directorio/establecimientos/" +
              establecimientos?.subcategoria?.slug,
          },
        ]}
      />
      <StyledBox>
        <Container>
          <br />
          <Box px="3%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: "1rem" }}
                textAlign="center"
              >
                Descubre {establecimientos?.subcategoria?.nombre} en Facatativá
              </Typography>
              <Box width="100%" px={{ xs: "0%", sm: "10%", md: "20%" }}>
                <OutlinedInput
                  fullWidth
                  placeholder="Buscar"
                  sx={{ backgroundColor: "white" }}
                  value={busqueda}
                  onChange={(event) => {
                    setBusqueda(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setBusqueda("")}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
            </Box>
            <br />
            <Grid container spacing={4}>
              {establecimientos?.establecimientos
                ?.filter((establecimiento) =>
                  establecimiento.nombre
                    .toLowerCase()
                    .includes(busqueda.toLowerCase())
                )
                .map((establecimiento, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <StyledElement
                      titulo={establecimiento.nombre}
                      subtitulo={establecimiento.telefono}
                      foto={establecimiento.foto}
                      href={"/directorio/detalles/" + establecimiento.slug}
                    />
                  </Grid>
                ))}
            </Grid>
            <br />
          </Box>
        </Container>
      </StyledBox>
      <Footer />
    </Box>
  );
};

export default Establecimientos;
