import { Candidaturas } from "pages/elecciones";

const routes = [
  {
    path: "",
    page: <Candidaturas />,
  },
];

export default routes;
