import { Categorias, Inmuebles, Inmueble } from "pages/inmobiliaria";

const routes = [
  {
    path: "",
    page: <Categorias />,
  },
  {
    path: "categorias/:tipo",
    page: <Inmuebles />,
  },
  {
    path: "inmueble/:inmueble_slug",
    page: <Inmueble />,
  },
];

export default routes;
