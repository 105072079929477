import { url_api } from "utils/constants";

const get_categorias_directorio = async (tipo) => {
  const url = url_api + "directorio/get_categorias_directorio/" + tipo;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const get_subcategorias_directorio = async (categoria) => {
  const url = url_api + "directorio/get_subcategorias_directorio/" + categoria;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const get_establecimientos = async (subcategoria) => {
  const url = url_api + "directorio/get_establecimientos/" + subcategoria;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

const get_establecimiento = async (establecimiento) => {
  const url = url_api + "directorio/get_establecimiento/" + establecimiento;
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

export {
  get_categorias_directorio,
  get_subcategorias_directorio,
  get_establecimientos,
  get_establecimiento,
};
